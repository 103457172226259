import React, {useRef, useState} from "react";
import vector from "../Assets/Form-Assets/Vector.png"
import emailjs from '@emailjs/browser';
import SuccessPopup from "./SuccessPopup";

const Form = () => {

    const form = useRef();

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [showPhoneValidationMessage, setShowPhoneValidationMessage] = useState(false);


    const usernameHandler = (e) => {
        setUsername(e.target.value);
    }

    const emailHandler = (e) => {
        setEmail(e.target.value);
    }

    const numberHandler = (e) => {
        const inputValue = e.target.value;
        setNumber(inputValue);

        if (!/^[0-9]{10,14}$/.test(inputValue)) {
            setShowPhoneValidationMessage(true);
        } else {
            setShowPhoneValidationMessage(false);
        }
    }


    const sendEmail = (e) => {
        e.preventDefault();

        if (username && email && number) {
            emailjs
                .sendForm('service_tgqer0o', 'template_w0rfrml', form.current, 'cAlQaKQsTk7uY5ZBj')
                .then((result) => {


                    setShowPopup(true);


                    setUsername("");
                    setEmail("");
                    setNumber("");

                    console.log(result.text);

                })
                .catch((error) => {
                    console.log(error.text);
                });
        } else {

            alert("Please fill in all required fields.");
        }

    };

    return (

        <div className="bg-gradient-to-b from-[#248a6e99] to-[#1d243399] rounded-lg w-[350px] h-[460px]  lg:h-[460px]  relative">
            <form className="flex flex-col z-20 relative" onSubmit={sendEmail} ref={form}>
                <h1 className="font-bold text-xl ml-5 mr-32 pt-5 ">Private Client Desk
                    Application Form</h1>
                <div className="flex flex-col justify-between space-y-5 mx-5 mt-10 relative">
                    <input type="text" name="user_name" className="bg-[#151515] rounded-md py-3.5 px-3 focus:outline-none focus:border-[#248A6E]  border-2 border-[#151515] "
                           placeholder="Full Name" onChange={usernameHandler} value={username} required/>
                    <input type="email" name="user_email" className="bg-[#151515] rounded-md py-3.5 px-3 focus:outline-none  focus:border-[#248A6E]  border-2 border-[#151515] "
                           placeholder="Email" onChange={emailHandler} value={email} required/>
                    <input type="text" name="user_number" className="bg-[#151515] rounded-md py-3.5 px-3 focus:outline-none  focus:border-[#248A6E]  border-2 border-[#151515] "
                           placeholder="Phone Number" onChange={numberHandler} value={number} required/>
                    {showPhoneValidationMessage && (
                        <p className="text-red-500 text-sm absolute -bottom-12 ">
                            Phone / mobile (ex: 8565452655). Without + or space character and between 10 to 14 digits
                        </p>
                    )}
                </div>
                <button type="submit" value="Send"
                        className="bg-[#248A6E] rounded-md py-4 px-3 font-bold  mt-14 lg:mt-16 mx-5 hover:bg-[#155443] ">Apply Now
                </button>
            </form>
            <img src={vector} alt="" className="absolute bottom-0 right-0 z-10 w-[280px]"/>
            {showPopup && (
                <SuccessPopup onClose={() => setShowPopup(false)}/>
            )}
        </div>

    )
}

export default Form;