import React from "react";
import Form from "./Form";


const Main = () => {

    return (
        <div className="w-full bg-main bg-top bg-cover bg-no-repeat md:bg-rigt md:bg-cover md:bg-no-repeat">
            <div className="max-w-[1300px] flex flex-col md:flex-row items-center text-start md:text-start mt-10 md:mt-0 justify-between mx-auto md:px-10 lg:px-0">
                <div className="md:mr-5 lg:mr-0 ml-4 md:ml-0 mx-5 md:mx-0">
                    <h1 className="text-4xl font-bold">ENJOY MANY BENEFITS <br/>
                        AND EXCLUSIVE VIP OFFERS</h1>
                    <p className="opacity-50 mt-3">
                        As a VIP member, you can enjoy exclusive gambling offers and <br/> preferential treatment at casinos.
                        These benefits can enhance your overall <br/> experience and provide you with more opportunities to
                        win.
                    </p>
                </div>
                <div className="mb-20 mt-20 ">
                    <Form/>
                </div>
            </div>
        </div>
    );
}

export default Main;