import React from 'react';
import logo from '../Assets/NavBar-Assets/logo.png';
import arrow from '../Assets/NavBar-Assets/arrow-left.svg';

const NavBar = () => {

    return (
        <div>
            <nav className="navbar w-full bg-gradient-to-r from-[#1D2433] to-[#248A6E]  ">
                <div className="flex flex-row justify-between mx-auto max-w-[1300px] items-center py-3 px-3 md:px-10 lg:px-0 ">
                   <div className="flex-1 md:flex-none"> <a href="/" className="flex flex-row items-center"> <img src={logo} alt=""/> <span className="font-bold ml-3 text-2xl">PRIVATE CLIENT DESK</span></a></div>
                    <div className="flex-1 md:flex-none"><a href="https://play.tether.bet/" className=""><button className="border rounded-md px-1 lg:px-10 py-2 flex flex-row"><img src= {arrow} alt="" className="mr-2"/>Back to tether.bet</button>
                    </a></div>
                </div>
            </nav>
        </div>
    );

}

export default NavBar;