import React from 'react';
import Navbar from './components/NavBar';
import Main from "./components/Main";
import Footer from "./components/Footer";
function App() {
  return (
    <div className="App text-white bg-[#151515]">
      <Navbar/>
        <Main/>
        <Footer/>
    </div>
  );
}

export default App;
