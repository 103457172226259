import React from "react";

const SuccessPopup = ({ onClose }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            {/* Background shadow */}
            <div className="fixed inset-0 bg-black opacity-50"></div>

            {/* Popup content */}
            <div className="bg-gradient-to-b from-[#248a6e99] to-[#1d243399] rounded-lg w-[350px] h-[300px] relative flex flex-col items-center pt-24 z-10">
                <h2 className="text-xl font-bold mb-2">Form Submitted Successfully!</h2>
                <button
                    className="bg-[#248A6E] text-white px-10 py-2 rounded-lg mt-4 font-bold hover:bg-[#155443] focus:outline-none"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default SuccessPopup;